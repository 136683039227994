import React from "react"
import Layout from "../components/common/layout"
import { graphql } from "gatsby"
import BlogPostPage from "../components/slugPages/blogPostPage"

const BlogPostTemplate = ({ data, location }) => (
  <Layout
    pageMeta={{
      title: `${data.strapiPost.titolo}`,
      keywords: [`${data.strapiPost.titolo}`],
      description: `${data.strapiPost.titolo}`,
      link: `/blog/${data.strapiPost.slug}`,
    }}
    location={location}
  >
    <BlogPostPage post={data.strapiPost} location={location} />
  </Layout>
)
export default BlogPostTemplate

export const postQuery = graphql`
  query BlogPostQuery($slug: String!) {
    strapiPost(slug: { eq: $slug }) {
      strapiId
      slug
      titolo
      data(formatString: "DD MMMM, YYYY", locale: "it")
      contenuto
      foto {
        name
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
