import React from "react"
import postPageStyles from "../../styles/slugPages/blog-post-page.module.scss"
import Img from "gatsby-image"
import FacebookIcon from "../../assets/facebook.svg"
import Markdown from "react-markdown"
import markdownStyles from "../../styles/common/markdown.module.scss"

const BlogPostPage = ({ post, location }) => {
  function LinkRenderer(props) {
    return <a href={props.href} target="_blank">{props.children}</a>
  }

  return (
    <>
      <div className={postPageStyles.post}>
        <div className={postPageStyles.fotoBox}>
          <Img
            className={postPageStyles.foto}
            fluid={post.foto.childImageSharp.fluid}
            alt={post.foto.name}
          />
        </div>
        <h2 className={postPageStyles.title}>{post.titolo}</h2>
        <div className={postPageStyles.header}>
        <span className={postPageStyles.date}>dp - {post.data}</span> <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon className={postPageStyles.icon} />
        </a></div>
        <Markdown className={markdownStyles.testo} renderers={{link: LinkRenderer}}>{post.contenuto}</Markdown>
      </div>
    </>
  )
}

export default BlogPostPage